import fetch from '@/utils/fetch.js';

//地址列表
export function list(params) {
	let data = Object.assign({}, params);
	return fetch({
		type: "/user/address/list",
		method: 'POST',
		data
	})
}
//编辑添加地址
export function edit(params) {
	let data = Object.assign({}, params);
	return fetch({
		type: "/user/address/edit",
		method: 'POST',
		data
	})
}
//获取收获地址详情
export function info(params) {
	let data = Object.assign({}, params);
	return fetch({
		type: "/user/address/info",
		method: 'POST',
		data
	})
}
//获取默认收获地址
export function addressDefault(params) {
	let data = Object.assign({}, params);
	return fetch({
		type: "/user/address/default",
		method: 'POST',
		data
	})
}
//删除收获地址
export function del(params) {
	let data = Object.assign({}, params);
	return fetch({
		type: "/user/address/del",
		method: 'POST',
		data
	})
}
//编辑添加地址 2.0
export function editV2(params) {
	let data = Object.assign({}, params);
	return fetch({
		type: "/v2/user/address/edit",
		method: 'POST',
		data
	})
}

//地址列表 2.0
export function listV2(params) {
	let data = Object.assign({}, params);
	return fetch({
		type: "/v2/user/address/list",
		method: 'POST',
		data
	})
}

//获取收获地址详情  2.0
export function infoV2(params) {
	let data = Object.assign({}, params);
	return fetch({
		type: "/v2/user/address/info",
		method: 'POST',
		data
	})
}
//获取默认收获地址
export function addressDefaultV2(params) {
	let data = Object.assign({}, params);
	return fetch({
		type: "/v2/user/address/default",
		method: 'POST',
		data
	})
}