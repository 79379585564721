<template>
	<el-dialog :visible.sync="show" :show-close="false" :close-on-click-modal="false" :before-close="close">
		<div slot="title">
			<div class="titleLeft">
				{{i18n('57')}}
			</div>
			<div class="titleRight" @click="close">
				<img src="@/assets/close.png" alt="">
			</div>
		</div>
		<div class="baiduMap__container" id="container" style="width: 100%; height: 299px;"
			ref="container"></div>
		<div class="ss">
			<input type="text" v-model="tagName" class="input"> <div @click="nearby_search">
				{{i18n('70')}}
			</div>
		</div>	
		<div class="list">
			<div scroll-y="true" class="scrollY">
				<div class="item" v-for="(item, index) in serviceDetail" :key="index" @click="seleClick(item)">
					<div class="left">
						<div class="one">
							{{item.name}}
						</div>
						<div class="two">
							{{item.vicinity}}
						</div>
					</div>
					<div class="right">
						<!-- <image :src="OSSImg+'gou.png'" alt="" mode="widthFix" v-if="seleData.id==item.id"></image> -->
						<img src="@/assets/gou.png" alt="" v-if="seleData.latitude==item.latitude">
					</div>
				</div>
			</div>
		</div>	
			
		<div class="but">
			<div class="" @click="close">
				{{i18n('55')}}
			</div>
			<div class="" @click="sub">
				{{i18n('56')}}
			</div>
		</div>
	</el-dialog>
</template>

<script>
	import {
		positionSearch
	} from '../../api/user.js'
	import {
		Loader
	} from "@googlemaps/js-api-loader";
	const loader = new Loader({
		// google服务的KEY
		apiKey: process.env.VUE_APP_.GOOGmapKEY,
		version: "weekly",
		libraries: ["places"]
	})
	export default {
		name: "mapWap",
		data() {
			return {
				show: false,
				serviceOptions: {
					city: '',
					estateName: '',
					tagName: '',
					lat: 14.558389,
					lng: 121.028686,
					show: false,
					province: "",
					district: "",
					street: ""
				},
				tagName:'',
				serviceDetail: true,
				seleData: {
					id: 0,
					longitude:false
				},
				TMap: false,
				mapInstance: false,
				markerList: [],
				isDjMap: false,
				iconH5: false
			}
		},
		methods: {
			seleClick(data) {
				let _this = this
				if(!this.seleData.longitude){
					_this.markerList = new _this.google.maps.Marker({
						draggable: false,
						map: _this.mapInstance,
						position: {
							lat: data.latitude,
							lng: data.longitude
						}
					});
				}
				_this.seleData = data
				_this.markerList.setPosition( new _this.google.maps.LatLng( data.latitude, data.longitude ) );
				_this.mapInstance.panTo( new _this.google.maps.LatLng( data.latitude, data.longitude ) );
			},
			async open() {
				let _this = this
				if (navigator.geolocation) {
					// let options = {
					// 	enableHighAccuracy: true,
					// 	maximumAge: 30000,
					// 	timeout: 12000
					// }
					// navigator.geolocation.getCurrentPosition((pos) => {
					// 	console.log(`纬度:${pos.coords.latitude}  经度:${pos.coords.longitude}`)
					// 	_this.serviceOptions.lat = pos.coords.latitude
					// 	_this.serviceOptions.lng = pos.coords.longitude
					// 	_this.canvasInt()
					// }, (pos) => {
					// 	console.log("pos")
					// 	console.log(pos)
					// 	_this.canvasInt()
					// }, options);
					_this.canvasInt()
				} else {
					console.log('当前环境不支持')
					this.canvasInt()
				}
				this.show = true
			},
			async canvasInt() {
				const container = document.getElementById('container')
				console.log("container")
				console.log(container)
				const position = {
					lat: this.serviceOptions.lat,
					lng: this.serviceOptions.lng
				};
				let _this = this
				loader.load()
					.then((google) => {
						console.log("google")
						console.log(google)
						_this.google = google
						_this.mapInstance = new google.maps.Map(
							container, {
								// 地图打开时的中心点位，根据自己需求自行更改
								center: position,
								// 地图缩放参数
								zoom: 14,
								// 最大缩放参数
								maxZoom: 18,
								// 最小缩放参数
								minZoom: 4,
								// 禁用默认UI
								disableDefaultUI: true
							}
						)
						_this.nearby_search()
					})
					.catch((e) => {
						console.log('报错')
						console.log(e)
					})
			},
			async nearby_search() {
				var _this = this;
				// 调用接口
				if (this.tagName != '') {
					this.serviceOptions.tagName = this.tagName
				}

				let {
					data
				} = await positionSearch({
					"longitude": this.serviceOptions.lng,
					"latitude": this.serviceOptions.lat,
					"keyword": this.serviceOptions.tagName,
					"language": this.$store.state.i18n.lang
				})
				console.log("data搜索地址结果")
				console.log(data)
				_this.mksH5(data)
			},
			//h5地图标点
			mksH5(data) {
				var _this = this;
				if (_this.serviceDetail.length == 1) {
					_this.serviceDetail = _this.serviceDetail.concat(data)
				} else {
					_this.serviceDetail = data
				}
			},
			// markertap(e){
			// 	let lat = e.lat()
			// 	let lng = e.lng()
			// 	let id = lat
			// 	let indexxx = 110000
			// 	this.serviceDetail.map((item, index) => {
			// 		if (item.latitude == id) {
			// 			this.seleData = item
			// 			indexxx = index
			// 		}
			// 	})
			// 	if (indexxx != 110000) {
			// 		this.serviceDetail.splice(indexxx, 1)
			// 		this.serviceDetail.unshift(this.seleData)
			// 	}
				
			// 	this.mapInstance.setCenter({
			// 		lat: lat,
			// 		lng: lng
			// 	})
			// },
			//h5点击目标点
			poitapH5() {
				// console.log("点击地图")
				// console.log(e)
				// let latitude = e.latLng.lat
				// let longitude = e.latLng.lng
				// this.mapInstance.setCenter(new TMap.LatLng(latitude, longitude));
				// this.markerLayer.setGeometries([])
				// this.markerLayer.add([{
				// 	id: latitude, //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
				// 	"styleId": 'myStyle', //指定样式id
				// 	"position": new TMap.LatLng(latitude, longitude), //点标记坐标位置
				// 	"properties": { //自定义属性
				// 		"title": e.name
				// 	}
				// }])
				// this.serviceDetail = [{
				// 	latitude: latitude,
				// 	longitude: longitude,
				// 	name: e.name,
				// 	vicinity: e.name,
				// 	id: latitude
				// }]
				// this.seleData = {
				// 	id: latitude,
				// 	latitude: latitude,
				// 	longitude: longitude,
				// 	name: e.name,
				// 	vicinity: e.name
				// }
				// this.nearby_search()
			},
			close() {
				this.show = false
			},
			sub() {
				delete this.seleData.marker
				if (this.seleData.id != 0) {
					this.$emit("seleLocation",this.seleData)
					this.show = false
				} else {
					if (this.$store.state.i18n.lang == 'zh') {
						this.$message.error('还没有选择地址')
					} else {
						this.$message.error('No address has been selected yet')
					}
				}
			}
		},
		computed: {
			lang() {
				return this.$store.state.i18n.lang
			},

		},
		mounted() {

		}
	};
</script>

<style scoped>
	.list {
		position: relative;
		flex: 1;
		height: 400px;
		overflow: hidden;
	}
	
	.scrollY {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		overflow-y: scroll;
	}
	.item {
		padding: 10px 15px;
		border-bottom: 1px solid rgba(0, 0, 0, .1);
		display: flex;
		align-items: center;
		justify-content: space-between;
		cursor: pointer;
	}
	
	.item .left {
		width: 300px;
	}
	
	.item .left .one {
		font-family: PingFangSC-Medium;
		font-size: 16px;
		color: #000000;
		font-weight: 500;
		margin-bottom: 10px;
	}
	
	.item .left .two {
		opacity: 0.6;
		font-family: PingFangSC-Regular;
		font-size: 16px;
		color: #000000;
		font-weight: 400;
	}
	
	.item .right image {
		width: 24px;
		height: 24px;
		display: block;
	}
	.ss {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 10px 0;
	}
	.ss .input{
		width: 400px;
		height: 32px;
		border: 1px solid rgba(170, 181, 195, 1);
		border-radius: 16px;
		padding: 0 16px;
		box-sizing: border-box;
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #86909C;
		line-height: 22px;
		font-weight: 400;
	}
	.ss div{
		width: 150px;
		height: 32px;
		border: 1px solid rgba(86, 171, 123, 1);
		border-radius: 16px;
		text-align: center;
		line-height: 32px;
		font-family: PingFangSC-Medium;
		font-size: 18px;
		color: #fff;
		font-weight: 500;
		background: #56AB7B;
		cursor: pointer;
	}
	.but {
		padding: 0 70px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.but div {
		cursor: pointer;
	}

	.but div:nth-child(1) {
		width: 197px;
		height: 44px;
		border: 1px solid rgba(86, 171, 123, 1);
		border-radius: 22.5px;
		text-align: center;
		line-height: 44px;
		font-family: PingFangSC-Medium;
		font-size: 18px;
		color: #56AB7B;
		font-weight: 500;
	}

	.but div:nth-child(2) {
		width: 226px;
		height: 44px;
		background: #56AB7B;
		border-radius: 22.5px;
		font-family: PingFangSC-Medium;
		font-size: 18px;
		color: #FFFFFF;
		text-align: center;
		font-weight: 500;
		line-height: 44px;
	}




	>>>.el-dialog__body {
		padding: 0 32px 40px 32px;
	}

	>>>.el-dialog {
		width: 662px;
		background: #FFFFFF;
		border-radius: 20px;
	}

	>>>.el-dialog__header {
		padding: 0 32px;
	}

	>>>.el-dialog__header div {
		height: 72px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	>>>.el-dialog__header .titleLeft {
		font-family: PingFangSC-Medium;
		font-size: 20px;
		color: #000000;
		letter-spacing: 0;
		font-weight: 500;
	}

	>>>.el-dialog__header .titleRight {
		width: 24px;
		height: 24px;
		background: #F2F3F5;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		cursor: pointer;
	}

	>>>.el-dialog__header .titleRight img {
		width: 12px;
		height: 12px;
		display: block;
	}

	>>>.el-input__icon {
		line-height: 30px;
	}

	>>>.el-input__inner {
		border: none;
		height: 30px;
	}

	>>>.el-input__inner:focus {
		border: none !important;
	}

	>>>.el-select .el-input.is-focus .el-input__inner {
		border: none !important;
	}
</style>
